/* src/components/Login.css */
* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: radial-gradient(circle at top,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%), radial-gradient(circle at bottom,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at right,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at left,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%);
    background-size: 3em 3em;
    background-color: #f4d8ab;
    opacity: 1
  }
  
  .login-container {
    width: 90%;
    max-width: 500px;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-header h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    font-family: "poppins";
  }
  
  .login-input {
    padding: 7px;
    margin-bottom: 5px;
    border: 3px solid #f4d8ab;
    border-radius: 5px;
    font-size: 16px;
    width: 90%;
  }
  
  .login-button {
    padding: 5px;
    background: #f4d8ab;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    transition: background 0.3s ease;
    width: 100px;
    font-family: "poppins";
    font-weight: 500;
  }
  
  .login-button:hover {
    background: #222222;
    color: #ffffff;
  }
  
  .login-error {
    color: rgb(116, 23, 23);
    margin-top: 10px;
    font-size: 14px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 500px) {
    .login-container {
      padding: 20px;
    }
  
    .login-header h2 {
      font-size: 12px;
    }
  
    .login-input, .login-button {
      padding: 5px;
      font-size: 12px;
    }
  }
  
  