* {
  box-sizing: border-box;
  
  
}

body {
  font-family: "poppins";
  
 
}

.order-form-container {
  max-width: 100%;
  margin: auto;
  margin-top: 0%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: radial-gradient(circle at top,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%), radial-gradient(circle at bottom,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at right,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at left,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%);
  background-size: 3em 3em;
  background-color: #f4d8ab;
  opacity: 1
}





.form-group {
  margin-bottom: 10px;
  position: relative;
  margin-top: 0%;
  
}

.form-group label {
  position: absolute;
  top: 17px;
  left: 5px;
  font-size: 11px;
  color: #929292;
  transition: 0.2s;
  pointer-events:none;
}

.form-group label.active {
  top: -9px;
  left: 10px;
  font-size: 10px;
  color: #929292;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px 80px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #ccc;
}

.error-text {
  color: #b43935;
  font-size: 12px;
  margin-top: 5px;
}

.perfume-choice {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-perfume-button {
  background-color: #1fae51;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius:50px;
  font-size: 15px;
  font-family: "poppins";

}

.delet-perfume-button {
  background-color: #ce1f19;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius:50px;
  font-size: 15px;
  font-family: "poppins";

}

.add-perfume-button:hover {
  background-color: #15883d;
  color: #ffffff;
}



.form-message {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

@media (max-width: 768px) {
  .perfume-choice {
    flex-direction: column;
  }
}

/* Base styling for the select element */
select {
  width: 100%; /* Full width on smaller screens */
  padding: 8px; /* Add some padding for better touch interaction */
  border: 1px solid #ccc; /* Light border for better visibility */
  border-radius: 4px; /* Rounded corners */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

/* Additional styling for smaller screens */
@media (max-width: 600px) {
  select {
    font-size: 16px; /* Larger font size for better readability */
  }
}

















.order-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 5px dashed #d11610;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
}

.offer-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 0%;
  color: #333;
  font-family: "Tajawal", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.offer-details {
  font-size: 1rem;
  color: #4b4b4b;
  margin-bottom: 0px;
  margin-top: 0%;
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.price1234 {
  font-size: 2.5rem;
  color: #d11610;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Tajawal", sans-serif;
  font-weight: 999;
  font-style: normal;
  
}



.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group input {
  padding: 11.5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: right;
  font-size: 1rem;
}

.submit-button {
  width: 100%;
  padding: 7px;
  font-size: 1.1rem;
  color: #ffffff;
  background-color: #1fae51;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 20px;
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  transition: background-color 0.3s ease;
  animation: heartbeat 1.5s infinite ease-in-out;
 
}

.submit-button:hover {
  background-color: #15883d;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.0);
  }
  75% {
    transform: scale(1.05);
  }
}


@media (max-width: 480px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}





.fixed-bottom {
  position: fixed;
  bottom: 10px; /* Ajuste la distance par rapport au bas */
  left: 0;
  right: 0;
  z-index: 999; /* Assure que le bouton reste au-dessus des autres éléments */
  background-color: #1fae51; /* Personnalisez selon vos besoins */
  width: 75%;
  margin: 0 auto; /* Centre horizontalement */
  text-align: center; /* Centre le contenu */
}

