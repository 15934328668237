.perfume-cardan {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin: 3px auto;
    background: radial-gradient(circle at top,transparent 9%, #4e5787 10% ,#4e5787 15% , transparent 16%), radial-gradient(circle at bottom,transparent 19%, #4e5787 20% ,#4e5787 25% , transparent 26%), radial-gradient(circle at right,transparent 19%, #4e5787 20% ,#4e5787 25% , transparent 26%), radial-gradient(circle at left,transparent 9%, #4e5787 10% ,#4e5787 15% , transparent 16%);
    background-size: 3em 3em;
    background-color: #535c8e;
    opacity: 1;
    padding: 15px 0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: "poppins";
    
  }
  
  .text-container {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "poppins";
    color: white;
  }
  
  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 90%;
    max-height: 90%; /* Ensures the image doesn’t overflow the container */
    object-fit: contain; /* Makes sure the full image is visible without cropping */
    border-radius: 8px;
    opacity: 90%;
  }
  
  .buy-button {
    padding: 10px 0px;
    background-color: #ffc700;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "poppins";



    
  }
  
  .buy-button:hover {
    background-color: #ffffff;
    color: #3f4b69;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .perfume-cardan {
      flex-direction: column;
    }
  
    .image-container img {
      width: 100%;
      height: auto; /* Ensure the image scales properly on small screens */
      object-fit: contain; /* Ensure the full image appears on smaller screens */
    }
  }
  .language-toggle2 {
    font-size: 0.8rem;
    padding: 8px 12px;
        background-color: #f4d8ab;
        color: #000000;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        font-family: "poppins";
     
      
   
  }

  
  .language-toggle2:hover {
    background-color: #3f4b69;
    color: #ffffff;
  }

  .arabic-font {
    font-family: 'Tajawal', sans-serif;
  }
  
 .titro {
  font-family: 'Poppins', sans-serif; /* A modern, clean font */
  font-size: 1.3rem; /* Larger font size for emphasis */
  font-weight: 600; /* Slightly bold */
  color: #ffffff; /* Modern dark blue color */
  text-align: center; /* Center the title */
  letter-spacing: 0.8px; /* Slight spacing between letters */
  border-bottom: 5px solid #ffc700; /* Light bottom border for style */
 
 
  }

 