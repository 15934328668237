/* Styles for the general tips section */
.tips-section {
  background-color: transparent;
  
  background-image: radial-gradient(#f4d8ab 0.5px, transparent 0.5px), radial-gradient(#f4d8ab 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
  padding: 20px;
  margin: 20px 0;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
  max-width: 890px;
  margin: 20px auto;
}

/* Scale effect on hover */
.tips-section:hover {
  transform: scale(1.02); /* Slightly increase size on hover */
}

/* Language toggle button */
.language-toggle {
  text-align: right;
  margin-bottom: 20px;
}

.language-toggle button {
  background-color: #f4d8ab;
  color: #6a4d1e;
  border: none;
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.language-toggle button:hover {
  background-color: #0f3160;
  color: #ffffff;
}

/* Centered Arabic title, left-aligned French title */
.tips-section h2 {
  text-align: center; /* Default center for both languages */
  color: #0f3160;
  margin-bottom: 7px;
  font-size: 2rem;
}

/* Left-align for French content */
.tips-section:not(.rtl) h2 {
  text-align: left; /* Left-align French title */
}

.tips-content h3 {
  color: #2fb961;
  margin-top: 10px;
  font-size: 1.2rem;
}

/* Left-align French tips content */
.tips-section:not(.rtl) .tips-content {
  text-align: left;
}

.tips-content ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

/* Increased line-height for readability */
.tips-content ul li {
  margin-bottom: 12px;
  font-size: 1.1rem;
  line-height: 1.5; /* Improved readability */
}

/* Arabic RTL settings */
.tips-section.rtl {
  direction: rtl;
  text-align: right;
  font-family: 'Tajawal', sans-serif; /* Arabic font */
}

/* Fade-in animation */
.fade-in {
  opacity: 0;
  animation: fadeInScale 0.8s forwards; /* Adjusted duration */
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.95); /* Start slightly smaller and below */
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1); /* End at original size and position */
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .tips-section {
    padding: 15px;
  }

  .tips-section h2 {
    font-size: 1.2rem;
  }

  .tips-content ul li {
    font-size: 1rem;
  }

  .tips-content h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .tips-section {
    padding: 10px;
    max-width: 100%; /* Ensure full width on smaller screens */
  }

  .language-toggle button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .tips-section h2 {
    font-size: 1rem;
  }

  .tips-content ul li {
    font-size: 0.9rem;
  }

  .tips-content h3 {
    font-size: 1rem;
  }
}

/* Arabic and French fonts */
.arabic-font {
  font-family: 'Tajawal', sans-serif;
}

.french-font {
  font-family: 'Poppins', sans-serif;
}

.tips-section:not(.rtl) .tips-content {
  margin-left: 10px; /* Add some left margin for French content */
}
.language-toggle button.active {
  background-color: #0f3160;
  color: #ffffff;
}



.tips-section h2 {
  position: relative; /* Position for pseudo-elements */

}

.tips-section h2::after {
  content: '';
  display: block;
  width: 80px; /* Width of the decorative line */
  height: 2.5px; /* Thickness of the line */
  background-color: #2fb961; /* Line color */
  margin: 10px auto; /* Center the line */
}




