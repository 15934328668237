/* ShopByType Component Styles */
.shop-by-type {
    padding: 5px;
    text-align: center;
    background-color: transparent;
    
    background-image: radial-gradient(#f4d8ab 0.5px, transparent 0.5px), radial-gradient(#f4d8ab 0.5px, transparent 0.5px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    border-radius: 10px;
    max-width: 90%;
    margin: 0 auto;
 
  }
  
  .shop-title {
    font-size: 1.6em;
    margin-bottom: 10px;
    font-family: 'poppins', sans-serif; /* Apply Tajawal font */
    color: #0f3160; /* Use the modern color you like */
    position: relative;
    padding-bottom: 9px;
  }
  
  .shop-title::after {
    content: '';
    width: 80px;
    height: 4px;
    background-color: #f15a24; /* Accent color */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  
  .shop-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .shop-item {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .shop-item:hover {
    transform: translateY(-5px);
  }
  
  .shop-icon {
    font-size: 4em;
 
 
  }
  
  .shop-title-item {
    font-size: 1.5em;
    
    font-weight: bold;
    color: #000000;
  }
  
  .shop-description {
    font-size: 1em;
    color: #535353;
   
    line-height: 1.2;
  }
  
  /* Arabic styles */
  .arabic .shop-title,
  .arabic .shop-item,
  .arabic .shop-description {
    text-align: center;
    font-family: 'Tajawal', sans-serif;
    direction: rtl;
  }
  
  .arabic .shop-title {
    font-size: 1.5em; /* Make Arabic title slightly larger */
    color: #0f3160; /* Accent color for Arabic */
  }
  
  .arabic .shop-item h3 {
    font-size: 1em;
  }
  
  .arabic .shop-description {
    font-size: 1em; /* Larger Arabic text for readability */
  }
  
/* Responsive Design: Adjusting for phones */
@media (max-width: 600px) {
  .shop-grid {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap if necessary */
    justify-content: space-between; /* Distribute items evenly */
    gap: 10px;
  }

  .shop-item {
    width: calc(50% - 10px); /* Make each item take 50% of the screen width with space for gap */
    font-size: 0.9em; /* Reduce font size to fit the content */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .shop-title-item {
    font-size: 1.2em; /* Reduce title font size */
  }

  .shop-description {
    font-size: 0.9em; /* Reduce description font size */
    line-height: 1.4; /* Slightly tighter line spacing */
  }
}
