/* src/App.css */
.App {
  text-align: center;
  font-family: poppins;
}

nav a {
  margin: 10px;
  text-decoration: none;
  color: #000000;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin: 10px;
}

button {
  margin: 10px;
}

table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 80%;
}

table, th, td {
  border: 1px solid rgb(255, 255, 255);
}

th, td {
  padding: 15px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

button {
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}

.contact-info {
  flex: 1;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  font-size: clamp(0.7rem, 2vw, 1.2rem); /* Further reduced minimum font size */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  text-align: center; /* Center text horizontally */
}

.contact-info span {
  display: inline-flex; /* Use inline-flex to ensure horizontal layout */
  align-items: center;
  gap: 10px;
  white-space: nowrap; /* Prevent text from wrapping */
}

@media (max-width: 768px) {
  header {
    flex-direction:row;
    align-items: flex-start;
  }
  header nav {
    text-align: center;
    margin-top: 10px;
  }
}
