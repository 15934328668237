/* General container styling */
.admin-page-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 80px; /* Ensure space for the fixed bottom container */
}

/* Space before the table */
.table-container {
  margin-top: 40px; /* Adds space before the table */
  margin-bottom: 20px;
}

/* Responsive table styling */
.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 1rem; /* Default font size */
}

.orders-table th {
  background-color: #f4d8ab;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table td {
  word-wrap: break-word;
  max-width: 200px; /* Controls width to avoid text overflow */
}

/* Buttons */
.delete-button {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "poppins";
  border: transparent;
  background-color: #bd2222;
  color: white;
}

.serve-button {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "poppins";
  border: transparent;
  background-color: #22bd50;
  color: white;
}

/* Fixed bottom container for search and export */
.fixed-bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 20px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  font-family: "poppins";
}

.fixed-bottom-container input,
.fixed-bottom-container button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: "poppins";
}

.fixed-bottom-container .search-input {
  flex: 1;
  margin-right: 10px;
}

.fixed-bottom-container .export-button {
  background-color: #27ae60;
  color: #fff;
  border: none;
  font-family: "poppins";
}

.fixed-bottom-container .export-button:hover {
  background-color: #219150;
  font-family: "poppins";
}

/* Pagination button */
.pagination-button {
  margin: 20px 0;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  font-family: "poppins";
}

.pagination-button:hover {
  background-color: #2980b9;
}

/* Success message */
.success-message {
  color: green;
  margin-top: 20px;
  text-align: center;
  font-family: "poppins";
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  /* Adjust font size and padding for medium screens */
  .orders-table th,
  .orders-table td {
    font-size: 0.9rem;
    padding: 6px;
  }
}

@media (max-width: 768px) {
  /* Further adjustments for smaller screens */
  .orders-table th,
  .orders-table td {
    font-size: 0.8rem;
    padding: 5px;
  }

  .fixed-bottom-container {
    flex-direction: column;
  }

  .fixed-bottom-container .search-input {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .fixed-bottom-container .export-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  /* Small screens (e.g., phones) - reduce font size further */
  .orders-table th,
  .orders-table td {
    font-size: 0.7rem;
    padding: 4px;
  }
  
  /* Make buttons and inputs adapt to screen width */
  .fixed-bottom-container input,
  .fixed-bottom-container button {
    font-size: 0.9rem;
    padding: 8px;
  }
}
