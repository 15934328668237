/* src/components/LandingPage.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #ffffff;
  color: #000000;
}

.landing-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  border-bottom: 1px solid #E9CA99;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #f4d8ab;
}

nav a {
  margin: 0 15px;
  color: #f4d8ab;
  text-decoration: none;
  font-weight: 500;
}

nav a:hover {
  color: #000000;
}

.hero {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-image: url('../assets/MAISON\ DE\ PARFUM\ MAROCAINE.png');
  background-size: cover; /* Ensures the image covers the entire container */
  background-repeat: no-repeat;
  background-position: center; /* Centers the image */
  animation: backgroundChange 20s infinite;
}


@media (max-width: 768px) {
  .hero {
    height: 35vh;
    font-size: 14px;
  }
}

.order-button {
  background-color: #3f4b69;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.order-button:hover {
  background-color: #3f3f3f;
  color: #ffffff;
  transform: scale(1.05); /* Slight zoom-in effect */
}


@media (max-width: 768px) {
  .order-button {
    padding: 5px 20px;
    margin-top: 50%;
  }
}



.buttonbar {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  background: radial-gradient(circle at top,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%), radial-gradient(circle at bottom,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at right,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at left,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%);
  background-size: 3em 3em;
  background-color: #f4d8ab;
  opacity: 1;
  padding: 15px 0;
}





















/* Ads zone */
.ads-zone {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
}

.ad {
  flex: 1;
  margin: 0 10px;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  color: #f4d8ab;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #f4d8ab;
}

.ad:hover {
  transform: translateY(-5px);
}

/* Order form section */
.order-form-section {
  background-color: #ffffff;
  padding: 40px 20px;
  margin: 20px 0;
  background-size: cover;
  background-position: center;
}

/* Media Queries for responsiveness */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .hero-content h1 {
      font-size: 2.5rem;
  }

  .ads-zone {
      flex-direction: column;
      align-items: center;
  }

  .ad {
      margin: 10px 0;
      width: 100%;
  }

  .order-form-section {
      padding: 20px;
  }

  .header {
      flex-direction: column;
      align-items: flex-start;
  }

  nav {
      margin-top: 10px;
  }

  nav a {
      margin: 5px 0;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .hero-content h1 {
      font-size: 2rem;
  }

  .order-button {
      padding: 10px 20px;
      font-size: 0.9rem;
  }

  .floating-whatsapp-button {
      bottom: 15px; /* Adjusted position */
      right: 5%; /* Adjusted position */
      width: 8%; /* Adjusted width */
  }
}

.perfume-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.perfume-brand {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fdfdfd;
  width: 300px; /* Adjust as needed */
}

.brand-title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
  text-align: center;
}

.perfume-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.perfume-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  
}

.perfume-name {
  flex: 1;
}

.perfume-colors {
  display: flex;
  gap: 5px;
}

.color-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.perfume-list-section {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 0%;
  margin-bottom: 0%;
  
}


.features-container {
  display: flex;
  justify-content: space-around;
  padding: 0px;
  background-color: #fff;
}

.feature-item {
  text-align: center;
  width: 100%;
}

.feature-icon i {
  font-size: 1rem;
  color: #333;
}

.feature-text h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
  text-align: center;
}

.feature-text p {
  font-size: 0.9rem;
  margin: 0;
  color: #777;
  text-align: center;
}

.feature-icon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.footer {
  background-color: rgb(255, 255, 255);
  color: #000000;
  padding: 20px;
  position: relative;
  bottom: 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 10px 20px;
}

.footer-content {
  flex: 1;
  text-align: left;
}

.footer-socials {
  flex: 1;
  text-align: right;
}







@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.0);
  }
  75% {
    transform: scale(1.05);
  }
}

.floating-whatsapp-button {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: #1fae51;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 1000;

  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  animation: pulse 1.5s infinite; /* Add the animation here */
}

.floating-whatsapp-button:hover {
  transform: scale(1.1);

}

@media screen and (max-width: 768px) {
  .floating-whatsapp-button {
    bottom: 50px;
    right: 15px;
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .floating-whatsapp-button {
    bottom: 50px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}










 

.logo-carousel {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  padding: 3px 0;
  
}

.carousel-track {
  display: inline-block;
  animation: scroll 50s linear infinite; /* Adjust animation speed if needed */
}

.carousel-item {
  display: inline-block;
  padding: 0 20px; /* Space between logos */
}

.carousel-item img {
  width: 55px; /* Set consistent width */
  height: 55px; /* Set consistent height */
  object-fit: contain; /* Maintain aspect ratio */
  display: block;
}

@keyframes scroll {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}






/* Ad Container */
.ad-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically if needed */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 10px; /* Add some space between ad items */
}

/* Ad Space */
.ad-space {
  flex: 1 1 auto; /* Allow the ad to grow and shrink */
  max-width: 100%; /* Make image responsive */
  max-height: 100%; /* Ensure the image does not exceed container */
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  padding: 10px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

/* Ad Space Image */
.ad-space img {
  max-width: 100%; /* Make image responsive */
  max-height: 100%; /* Ensure the image does not exceed container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .ad-space {
    padding: 0px; /* Adjust padding for smaller screens if needed */
  }
}

@media (max-width: 480px) {
  .ad-container {
    gap: 5px; /* Reduce gap for very small screens */
  }
}





.content-container {
  flex: 1;
  min-width: 100%; /* Ensures content does not get too narrow */
  margin: 0 16px;
}

.card-slider {
  display: flex;
  flex-direction: column;
}

.bar123 {
  margin-bottom: 0px;
}

.bar123 input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 5px solid #f4d8ab;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: "poppins";
}

.perfume-card {
  border: 5px solid #f4d8ab;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
  
}

.brand-title {
  font-size: 1.8rem; /* Larger size for modern look */
  font-weight: bold; /* Strong emphasis on the title */
  color: #1f1f1f; /* Neutral dark color for versatility */
  margin-bottom: 15px; /* Adequate spacing below */
  text-align: center; /* Center the text */
  text-transform: capitalize; /* Makes each word start with a capital letter */
  position: relative;
 

  border-radius: 50px; /* Rounded corners for modern feel */
  background-color: #ffffff; /* Light grey background */

  overflow: hidden;
  transition: all 0.3s ease-in-out; /* Smooth transition on hover */
}

.brand-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #3f4b69; /* Accent color line under the title */
  border-radius: 5px;
}

.brand-title:hover {
  background-color: #3f4b69; /* Changes background color on hover */
  color: #ffffff; /* Inverts text color on hover */

}


.perfume-list {
  list-style: none;
  padding: 0;
  margin: 0;

}

.perfume-list.vertical-list {
  display: list-item;
  flex-direction: column;
}

.perfume-item {
  border-bottom: 0px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}

.perfume-item:last-child {
  border-bottom: none;
}

.perfume-name {
  font-size: 1rem;
  color: #555;
  text-align: start;
}

.perfume-colors {
  display: flex;
  gap: 5px;
}

.color-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.navigation-buttons button {
  background-color: #f4d8ab;
  color: #ffffff;
  border-radius: 50px;
  padding: 10px 10px;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0 30px;
  display: flex;
  align-items: center;
  border-color: transparent;
}

.navigation-buttons button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.navigation-buttons svg {
  font-size: 1.3rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .ad-container {
    flex-direction: column;
    align-items: center;
  }

  .ad-space {
    width: 100%;
    margin: 8px 0;
  }

  .content-container {
    margin: 8px 0;
  }
}












.features-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: transparent;
  
  background-image: radial-gradient(#f4d8ab 0.5px, transparent 0.5px), radial-gradient(#f4d8ab 0.5px, transparent 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.feature-item {
  text-align: center;
  width: 20%;
  min-width: 100px;
  padding: 5px;
  background-color: transparent;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  
}

.feature-item:hover {
  transform: translateY(-10px);
}





.feature-text h3 {
  font-size: 1rem;
  color: #222222;
  margin-bottom: 10px;
}

.feature-text p {
  font-size: 0.9rem;
  color: #666666;
  line-height: 1.4;
  text-align: start;
  text-align: center;
}

/* Mobile responsiveness */
@media (max-width: 700px) {
  .features-container {
    flex-direction: column;
    align-items: center;
  }

  .feature-item {
    width: 96%;
  
  }
}












.footer {
  
  color: #292929;
  padding: 20px 0;
  font-family: 'poppins', sans-serif;
  background: radial-gradient(circle at top,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%), radial-gradient(circle at bottom,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at right,transparent 19%, #e9ca99 20% ,#e9ca99 25% , transparent 26%), radial-gradient(circle at left,transparent 9%, #e9ca99 10% ,#e9ca99 15% , transparent 16%);
  background-size: 3em 3em;
  background-color: #f4d8ab;
  opacity: 1;
  padding: 15px 0;
  
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #292929;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.social-icons {
  margin-top: 15px;
  display: flex;
  gap: 25px;
}

.social-icons a {
  color: #292929;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffffff; /* Gold color for hover effect */
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    gap: 20px;
  }
}



.scrollingaard {
  background-color: #000000;
 
}


.scrolling-text {

  background-color: #000000;
  font-family: "tajawal";
  padding: 5px;
  text-align: center;
 
}

.scrolling-text p {
  font-size: 15px; /* Adjust font size as needed */
  color: rgb(255, 255, 255); /* Example text color */
  margin: 0;
  margin-top: 42px;
  text-align: center;
  

}



.perfume-item.homme {
  color: #2a6fbe;
  
}

.perfume-item.femme {
  color: #e1788a;
  
}


.filterbuttons12 {
  padding: 10px 10px;
  
  
}

.filterbuttonsT {
  background-color: #f4d8ab;
  color: #161616;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 30px;
  align-items: center;
  border-color: transparent;
  
}

.filterbuttonsX {
  background-color: #e1788a;
  color: #ffffff;
  border-radius: 50%; /* Make the button circular */
  
  font-size: 1rem;
  cursor: pointer;
  margin: 0 30px;
  align-items: center;
  border-color: transparent;

  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
}

.filterbuttonsY {
  background-color: #2a6fbe;
  color: #ffffff;
  border-radius: 50%; /* Make the button circular */
  
  font-size: 1rem;
  cursor: pointer;
  margin: 0 30px;
  align-items: center;
  border-color: transparent;

  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
}


@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulsating-stars {
  display: inline-block;
  animation: pulsate 1s ease-in-out infinite;
}






